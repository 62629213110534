import { Container, Card, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Logo from '../../../assets/img/common/logo.png';
import React, { useEffect, useState } from "react";
import { adminAuth } from '../../../services/auth';
import { HeadFC, navigate } from 'gatsby';

import './index.scss';

const AdminLogin = () => {
  const [isLogged, setIsLogged] = useState(true);
  const [blockSubmit, setBlockSubmit] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    const userJwt = localStorage.getItem("Authorization");
    setIsLogged(!!userJwt);

    if (userJwt) {
      navigate("/admin");
      return;
    }
  }, []);

  useEffect(() => {
    setErrorMessage('');
    if (userCredentials.email.trim() === '' || userCredentials.password.trim() === '') {
      setBlockSubmit(true);
      return;
    }
    setBlockSubmit(false);
  }, [userCredentials]);

  const onChangeInput = (e: any, type: string) => {
    setUserCredentials((prevState) => ({
      ...prevState,
        [type]: e.target.value
    }));
  }

  const onSubmitLoginForm = async (e: any) => {
    e.preventDefault();
    setErrorMessage('');
    setBlockSubmit(true);

    toast.promise(doLogin,
      {
        pending: 'Comprobando las credenciales',
        success: 'Iniciando sesión 👌',
        error: 'Usuario o contraseña incorrecta 🤯'
      });

    setBlockSubmit(false);
  }

  const doLogin = async () => {
    try {
      const loginResponse = await adminAuth(userCredentials.email, userCredentials.password);
      const accessToken = loginResponse?.data?.accessToken;
      if (accessToken) {
        localStorage.setItem("Authorization", accessToken);
        navigate("/admin");
      }
    } catch { }
  }

  return (
    <>
      {!isLogged
      &&
      <>
        <ToastContainer />
        <Container className="admin-login">
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={8} className="d-flex justify-content-center align-items-center">
              <Card className="p-4 w-50">
                <Form onSubmit={(e) => onSubmitLoginForm(e)}>
                  <FormGroup className="d-flex justify-content-center">
                    <img src={Logo} alt="Labotana Logo" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="login_email_text" className="mb-2">
                      Nombre de usuario o correo electrónico
                    </Label>
                    <Input
                      type="email"
                      id="login_email_text"
                      onChange={(e) => onChangeInput(e, 'email')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="password_text" className="mb-2">
                      Contraseña
                    </Label>
                    <Input
                      type="password"
                      id="password_text"
                      onChange={(e) => onChangeInput(e, 'password')}
                    />
                  </FormGroup>
                  <FormGroup>
                    {errorMessage}
                  </FormGroup>
                  <FormGroup>
                    <Button
                      type="submit"
                      color="success"
                      className="w-100"
                      disabled={blockSubmit}
                    >
                      Acceder
                    </Button>
                  </FormGroup>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
      }
    </>
  );
}

export default AdminLogin;

export const Head: HeadFC = () => <title>LaBotana : Admin - Login</title>
